export default {
  "": {
    "id": 174,
    "temp_key": "",
    "site_id": 11967,
    "name": "",
    "items": [
      {
        "id": "42al3v5c8ggs",
        "use": "page",
        "name": "Úvod",
        "page": "/",
        "section": ""
      },
      {
        "id": "3tmfhr4akvb8",
        "use": "page",
        "name": "Služby",
        "page": "/",
        "section": "sluzby1"
      },
      {
        "id": "cwdr55jtvn0d",
        "use": "page",
        "name": "Ceník",
        "page": "/",
        "section": "cenik"
      },
      {
        "id": "h6hfw1o5eyph",
        "use": "page",
        "name": "Kontakt",
        "page": "/",
        "section": "kontakt"
      },
      {
        "id": "gic5zq5foj41",
        "url": "https://www.solers.legal",
        "use": "url",
        "name": "centrála Praha"
      }
    ]
  }
}